import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import {
  CreateRegistryInputs,
  Registry,
} from "../../../../../model/container/Registry";
import { RegistryList } from "../../../../../model/container/RegistryList";
import GeneralStep from "./components/GeneralStep";
import LoginDataStep from "./components/LoginDataStep";

export const CreateRegistry: FC = () => {
  const goto = useGotoLink();
  const { projectId } = usePathParams("projectId");
  const existingRegistries = RegistryList.useLoadAllByProjectId(projectId);

  const form = useForm<CreateRegistryInputs>({
    translationKey: "createRegistry",
    showSuccessFeedback: true,
    defaultValues: {
      description: "",
      url: "",
      loginType: "anonymous",
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      const registryId = await Registry.createNew(values, projectId);

      goto("registryDetails", { registryId }, { replace: true });
    },
  });

  return (
    <WizardModal form={form} steps={["general", "loginData"]}>
      <GeneralStep registries={existingRegistries} />
      <LoginDataStep />
    </WizardModal>
  );
};

export default CreateRegistry;
