import { Box } from "@mittwald/flow-components/dist/components/Box";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { RegistryLoginInputs } from "../../../components/RegistryLoginInputs";

export const LoginDataStep: FC = () => {
  const form = useFormContext();
  const url = form.watch("url");

  return (
    <WizardStep id="loginData" indicatorText="loginData">
      <Box _itemGap="m">
        <Section.Item headline="loginData">
          <Text i18n="description" />
          <RegistryLoginInputs url={url} />
        </Section.Item>
      </Box>
    </WizardStep>
  );
};

export default LoginDataStep;
