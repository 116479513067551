import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form/hooks/useForm";
import { InlineCode } from "@mittwald/flow-components/dist/components/InlineCode";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import React, { FC } from "react";
import { Registry } from "../../../../model/container/Registry";

interface Props {
  url: string;
}

export const RegistryLoginInputs: FC<Props> = (props) => {
  const form = useFormContext();
  const loginType = form.watch("loginType");
  const registryMeta = Registry.getKnownRegistryMeta(props.url);
  const registryMetaContent =
    registryMeta && loginType === "password" ? (
      <Text
        i18n={{
          id: "registryMeta",
          values: {
            scope: <InlineCode>{registryMeta.scope}</InlineCode>,
            helpLink: (
              <TextLink action={registryMeta.helpLink} i18n="createHere" />
            ),
          },
        }}
      />
    ) : undefined;

  return (
    <>
      <SelectBox
        name="loginType"
        options={[
          { title: "anonymous", value: "anonymous" },
          { title: "passwordLogin", value: "password" },
        ]}
      />
      {registryMeta && loginType === "password" && (
        <Text
          i18n={{
            id: "recommendation",
            values: { additionalInfo: registryMetaContent },
          }}
        />
      )}
      {loginType === "password" && (
        <ColumnLayout medium={[1, 1]}>
          <TextField
            label="username"
            name="username"
            rules={{ required: true }}
          />
          <TextField
            label="password"
            name="password"
            rules={{ required: true }}
            type="password"
          />
        </ColumnLayout>
      )}
    </>
  );
};
