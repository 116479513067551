import {
  Suggestion,
  SuggestionsFactory,
} from "@mittwald/flow-components/dist/components/AutoCompleteField";
import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { List } from "@mittwald/flow-components/dist/components/List";
import { HeavyListItemProps } from "@mittwald/flow-components/dist/components/List/components/HeavyListItem/types";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC, useEffect, useRef, useState } from "react";
import { UseFieldArrayRemove } from "react-hook-form";
import {
  AiMetaInfo,
  ContainerEnvVariable,
  ContainerImageConfigEnv,
} from "../../../../../model/container/Container";
import { ContainerUtils } from "../../../../../model/container/ContainerUtils";
import { AiDescription } from "../AiDescription";

interface Props extends HeavyListItemProps {
  index: number;
  suggestedEnvs?: ContainerImageConfigEnv[];
  remove: UseFieldArrayRemove;
  setAiGeneratedStates: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export const EnvironmentHeavyListEntry: FC<Props> = (props) => {
  const { index, suggestedEnvs = [], remove, setAiGeneratedStates } = props;
  const form = useFormContext();
  const [metaInfo, setMetaInfo] = useState<AiMetaInfo | undefined>();
  const envSuggestionsUpdated = useRef(false);

  const envSuggestions: SuggestionsFactory<unknown> = (value) => {
    const cleanedValue = value.trim().toLowerCase();
    const suggestions = suggestedEnvs
      .map((e) => ({ value: e.key, label: { text: e.key } }))
      .filter(
        (s) =>
          !form
            .getValues("environments")
            .find((f: ContainerEnvVariable) => f.key === s.value),
      );

    if (cleanedValue === "") {
      return suggestions;
    }

    return suggestions.filter((s) =>
      s.value.toLowerCase().includes(cleanedValue),
    );
  };

  const handleSuggestionSelect = (
    option: Suggestion<unknown>,
    index: number,
  ): string => {
    form.setValue(
      `environments.${index}.value`,
      suggestedEnvs.find((e) => e.key === option.value)?.value,
    );
    return option.value;
  };

  const syncFields = (): void => {
    const values = form.getValues("environments");
    form.setValue(
      "environmentText",
      ContainerUtils.mapEnvEntriesToText(values),
    );
  };

  const updateAiInfo = (value: string): void => {
    const matchingEnv = suggestedEnvs.find((e) => e.key === value);
    setMetaInfo(
      matchingEnv
        ? {
            description: matchingEnv.description,
            isAiGenerated: matchingEnv.isAiGenerated,
            isSensitive: matchingEnv.isSensitive,
          }
        : undefined,
    );

    setAiGeneratedStates((prev) => {
      prev[index] = !!matchingEnv?.isAiGenerated;
      return prev;
    });
  };

  const handleChange = (value: string, type: "key" | "value"): void => {
    syncFields();
    if (type === "key") {
      updateAiInfo(value);
    }
  };

  const removeItem = (index: number): void => {
    remove(index);
    updateAiInfo(form.getValues(`environments.${index}.key`));
    syncFields();
  };

  useEffect(() => {
    if (envSuggestionsUpdated.current || suggestedEnvs.length === 0) return;
    envSuggestionsUpdated.current = true;
    updateAiInfo(form.getValues(`environments.${index}.key`));
  }, [suggestedEnvs]);

  return (
    <List.HeavyItem closeAction={() => removeItem(index)}>
      <ColumnLayout medium={[1, 1]}>
        <AutoCompleteField
          gradientBorder={metaInfo?.isAiGenerated}
          label="key"
          name={`environments.${index}.key`}
          rules={{ required: true }}
          suggestOnEmptyValue
          suggestions={envSuggestions}
          onBeforeSuggestionSelected={(option) =>
            handleSuggestionSelect(option, index)
          }
          onChange={(v) => handleChange(v, "key")}
        />
        <TextField
          gradientBorder={metaInfo?.isAiGenerated}
          label="value"
          name={`environments.${index}.value`}
          rules={{ required: true }}
          type={metaInfo?.isSensitive ? "passwordWithGenerator" : undefined}
          onChange={(v) => handleChange(v, "value")}
        />
      </ColumnLayout>
      {metaInfo?.description && (
        <AiDescription description={metaInfo.description} />
      )}
    </List.HeavyItem>
  );
};
