import { MittwaldApi } from "../../api/Mittwald";
import { AppInstallation } from "../app/AppInstallation";
import { Container } from "../container/Container";

export type AccessApiData =
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Sshuser_SshUser
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Sshuser_SftpUser;

export type PublicKeyApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Sshuser_PublicKey;

export type AccessAuthType =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Sshuser_AuthType;

export type AccessLevel =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Sshuser_AccessLevel;

export type SftpUserApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Sshuser_SftpUser;

export type SshUserApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Sshuser_SshUser;

const accessAuthTypes: Record<AccessAuthType, true> = {
  publicKey: true,
  password: true,
};

export const allAccessAuthTypes = Object.keys(
  accessAuthTypes,
) as AccessAuthType[];

const accessLevels: Record<AccessLevel, true> = {
  full: true,
  read: true,
  unspecified: true,
};

export const allAccessLevels = Object.keys(accessLevels) as AccessLevel[];

export interface UpdatePasswordInputs {
  password: string;
}

export interface AddPublicKeyInputs {
  key: string;
  comment: string;
}

export interface NewSshUserInputs {
  description: string;
  password: string;
  key: string;
  comment: string;
  authType: AccessAuthType;
  expiresAt?: string;
}

export interface UpdateSshUserInputs {
  description?: string;
  active?: boolean;
  expiresAt?: string;
}

export interface NewSftpUserInputs extends NewSshUserInputs {
  directories: DirectoryEntry[];
  accessLevel: "read" | "full";
}

export interface UpdateSftpUserInputs extends UpdateSshUserInputs {
  directories?: DirectoryEntry[];
  accessLevel?: "read" | "full";
}

export interface DirectoryEntry {
  path: string;
}

export type SshConnectableInstance = AppInstallation | Container;
export type SshConnectableInstanceType = "app" | "container";
